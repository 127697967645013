html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

/* * {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
} */

*,
*:before,
*:after {
  box-sizing: border-box;
}

.icon {
  min-height: 20px;
  min-width: 20px;
}

/* html,
body,
#root {
  min-height: 100vh;
} */

html {
  scroll-behavior: smooth;
}

body {
  /* margin-bottom: 64px; */
}

input {
  accent-color: #ffffff;
  outline: none;
}

input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type='radio']::before {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ffffff;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

/* Hide scrollbar for Chrome, Safari and Opera */

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@font-face {
  font-family: 'MTN-Bold';
  src: local('MTN-Bold'), url(./fonts/MTNBrighterSans-Bold.woff) format('woff');
}
@font-face {
  font-family: 'MTN';
  src: local('MTN'), url(./fonts/MTNBrighterSans-Regular.woff) format('woff');
}

body {
  font-family: 'MTN';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #000;;
}

.text-center {
  text-align: center;
  margin: auto;
}

.bottom {
  align-self: flex-end;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  outline: none;
}

@font-face {
  font-family: 'MTNBrighterSansRegular';
  src: local('MTNBrighterSansRegular'), url(./fonts/MTNBrighterSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'MTNBrighterSansBold';
  src: local('MTNBrighterSansBold'), url(./fonts/MTNBrighterSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'MTNBrighterSansLight';
  src: local('MTNBrighterSansLight'), url(./fonts/MTNBrighterSans-Light.woff) format('woff');
}

@font-face {
  font-family: 'MTNBrighterSansExtraBold';
  src: local('MTNBrighterSansExtraBold'), url(./fonts/MTNBrighterSans-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'MTNBrighterSansExtraLight';
  src: local('MTNBrighterSansExtraLight'), url(./fonts/MTNBrighterSans-ExtraLight.woff) format('woff');
}

/*
* Zigi Chat Bubble Placement
*/

#mtnbot-container .open-chatbox.show {
	bottom: 85px !important;
	right: 23px !important;
}

#mtnbot-container.show_balloon:not(.closed) .balloon{
	bottom: 130px !important;
	right:53px !important;
}

#mtnbot-container.hide {
	display: none !important;
}

a[disabled] {
	pointer-events: none;
}